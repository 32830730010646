module.exports = [{
      plugin: require('C:/Users/shawn/projects/massivelines-portfolio/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":768},
    },{
      plugin: require('C:/Users/shawn/projects/massivelines-portfolio/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-114418107-1"},
    },{
      plugin: require('C:/Users/shawn/projects/massivelines-portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
